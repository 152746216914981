import React, { useState } from 'react'
import styled from 'styled-components'
import { View } from '@components/layout'
import { H1 } from '@components/typography'
import {
  FreeDownloads,
  HomeTestimonials,
  MainBanner,
  Mentoring,
  MoreAboutMartin,
  Programs,
  Stats,
  WhatMakesUsDifferent,
  WhoWeveHelped,
} from './Sections'
import { CarouselTestimonials } from './Sections/CarouselTestimonials/CarouselTestimonials'
import { HomeModals, homeModals } from './Sections/Modals/HomeModals'

const Container = styled(View)`
  height: 100%;
  width: 100%;
`

const Home = () => {
  const [visibleModal, setVisibleModal] = useState(null)
  return (
    <Container>
      <H1>The No 1 Learning and Mentoring Course for Entrepreneurs</H1>
      <MainBanner onClickButton={() => setVisibleModal(homeModals.prospectus)} />
      <Stats />
      <FreeDownloads setVisibleModal={setVisibleModal} />
      <WhoWeveHelped />
      <CarouselTestimonials />
      <WhatMakesUsDifferent />
      <Mentoring />
      <MoreAboutMartin />
      <HomeTestimonials />
      <Programs />
      {/* <GetStarter /> */}

      <HomeModals
        visibleModal={visibleModal}
        hideAllModals={() => setVisibleModal(null)}
        showSuccessModal={() => setVisibleModal(homeModals.success)}
      />
    </Container>
  )
}

export default Home
