import styled, { css, useTheme } from 'styled-components'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { media } from 'styled-bootstrap-grid'
import { shadows } from 'theme/styles'
import PrimaryCTA from '@components/input/Button'
import { View } from '@components/layout'
import { H2, P } from '@components/typography'
import { IconListItem } from '@components/typography/IconListItem'

const Container = styled(View)`
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray};
  border-radius: 20px;
  ${shadows.subtle}
  flex: 1;
  margin: ${(props) => props.theme.spacing.sm}px;
  margin-top: ${(props) => props.theme.spacing.lg * 1.5}px;
  overflow: hidden;
  max-width: 720px;

  // Switch media query to 1380px if Success Program (i.e. a 4th card) is re-instated.
  @media (min-width: 1080px) {
    height: ${(props) => (props.tall ? 900 : 590)}px;
  }
`

const TitleWrapper = styled(View)`
  padding: ${(props) => props.theme.spacing.lg}px;
  padding-top: ${(props) => props.theme.spacing[props.titleTopPadding]}px;
  padding-bottom: 0;
  background: ${(props) => props.theme.colors[props.titleBackground]};
`

const Title = styled(H2)`
  color: ${(props) => props.theme.colors[props.titleColor]};
  font-size: 30px;
  margin-bottom: ${(props) => props.theme.spacing.sm}px;
  text-align: left;
  align-self: flex-start;
  width: 100%;
  font-weight: bold;
`

const SubtitleWrapper = styled(View)`
  padding-left: ${(props) => props.theme.spacing.lg}px;
  background: ${(props) => props.theme.colors[props.subtitleBackground]};
`

const Subtitle = styled(P)`
  color: ${(props) => props.theme.colors[props.subtitleColor]};
  margin: 0;
  font-weight: bold;
  padding: ${(props) => props.theme.spacing.xs}px 0;
`

const Body = styled(View)`
  flex: 1;
  padding: ${(props) => props.theme.spacing.lg}px;
  padding-top: ${(props) => props.theme.spacing.md}px;
  background: ${(props) => props.theme.colors[props.bodyBackground]};
  justify-content: space-between;
`

const BodyTop = styled(View)``

const Description = styled(P)`
  color: ${(props) => props.theme.colors[props.bodyColor]};

  // ${media.md`
  //   height: 110px;
  // `}
`

const Divider = styled(View)`
  width: 100%;
  border-bottom: 1px dashed ${(props) => props.theme.colors[props.dividerColor]};
`

const Bullets = styled(View)`
  padding: ${(props) => props.theme.spacing.md}px 0;
  padding-top: ${(props) => props.theme.spacing.lg}px;
`

const Button = styled(PrimaryCTA)`
  background: ${(props) => props.theme.colors[props.buttonBackground]};

  :hover {
    ${(props) =>
      props.buttonBackground === 'white' &&
      css`
        color: ${props.theme.colors.secondaryDark};
        background: ${props.theme.colors.grayLighter};
      `}
  }
`

export const ProgramCard = ({
  bodyBackground,
  bodyColor,
  bulletIconBackgroundColor,
  bullets,
  buttonBackground,
  buttonTextColor,
  description,
  dividerColor,
  href,
  subtitle,
  subtitleBackground,
  subtitleColor,
  tall,
  title,
  titleBackground,
  titleColor,
  titleTopPadding,
}) => {
  const theme = useTheme()
  return (
    <Container key={title} tall={tall}>
      {title === 'Raising Funds for Business' || title === 'Idea to Exit Masterclass' ? (
        <TitleWrapper titleBackground={titleBackground} titleTopPadding={titleTopPadding}>
          <Title titleColor={titleColor}>{title}</Title>
        </TitleWrapper>
      ) : (
        <TitleWrapper titleBackground={titleBackground}>
          <Title titleColor={titleColor}>{title}</Title>
        </TitleWrapper>
      )}
      <SubtitleWrapper subtitleBackground={subtitleBackground}>
        <Subtitle subtitleColor={subtitleColor}>{subtitle}</Subtitle>
      </SubtitleWrapper>
      <Body bodyBackground={bodyBackground}>
        <BodyTop>
          <Description bodyColor={bodyColor}>{description}</Description>
          <Divider dividerColor={dividerColor} />
          <Bullets>
            {bullets.map((bullet) => (
              <IconListItem
                iconColor={bodyBackground}
                iconBackgroundColor={bulletIconBackgroundColor}
                textColor={bodyColor}
                key={bullet}
                iconSize={12}
              >
                {bullet}
              </IconListItem>
            ))}
          </Bullets>
        </BodyTop>
        <Link href={href}>
          <Button buttonBackground={buttonBackground} color={theme.colors[buttonTextColor]}>
            Find Out More
          </Button>
        </Link>
      </Body>
    </Container>
  )
}

ProgramCard.propTypes = {
  bodyBackground: PropTypes.string.isRequired,
  bodyColor: PropTypes.string.isRequired,
  bulletIconBackgroundColor: PropTypes.string.isRequired,
  bulletIconColor: PropTypes.string.isRequired,
  bullets: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  buttonBackground: PropTypes.string.isRequired,
  buttonTextColor: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dividerColor: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  subtitleBackground: PropTypes.string.isRequired,
  subtitleColor: PropTypes.string.isRequired,
  tall: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleBackground: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
}
